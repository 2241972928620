import React from 'react';
import './Main.scss';
import LightSwitch from '../LightSwitch/LightSwitch';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import Contact from '../Contact/Contact';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import ArrowToTop from '../ArrowToTop/ArrowToTop';
import ContactLinks from '../ContactLinks/ContactLinks';
import Footer from '../Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

export default function Main(props) {

    const contactSent = props.contactSent;

    function changeDarkMode(mode) {
        if (mode) {
            document.getElementsByClassName("appContainer")[0].id = "";
        }
        else {
            document.getElementsByClassName("appContainer")[0].id = "lightMode";
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            toast(
                <div>
                    <p>Website is Under Renovation</p>
                    <img src={require('../../assets/gif/roller.gif')} alt="roller" />
                    <p>Enjoy the Old Version!</p>
                </div>
            );

        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="appContainer" id="appContainer">
            <LightSwitch changeDarkMode={changeDarkMode} />
            <Header />
            <Navbar />
            <Skills />
            <Projects />
            <Contact contactSent={contactSent} />
            <ArrowToTop />
            <ContactLinks />
            <Footer />
            <ToastContainer position="top-center" autoClose={false} toastClassName="custom-toast" />
        </div>
    );
}