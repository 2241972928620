import React from 'react';
import './App.scss';
import Main from './components/Main/Main';


function App() {

  return (
    <Main />
  );
}

export default App;
