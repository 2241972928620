import React, { useState } from 'react';
import './ThankYou.scss';
import { Puff } from 'react-loader-spinner';


export default function Loading() {
    const [thankYou1, setThankYou1] = useState("");
    const [thankYou2, setThankYou2] = useState("");
    const [visible, setVisible] = useState(true);

    setTimeout(() => {
        setVisible(false);
        let loader = document.getElementById("loader");
        let thankYou = document.getElementById("thankYouContent");
        if (loader && thankYou) {
            loader.style.display = "none";
            thankYou.style.display = "initial";
        }
        setThankYou1("Thank You for your message!");
        setThankYou2("I'll get back to you ASAP.");
    }, 3000)




    return (
        <div id="thankYouContainer">
            <div id="loader">
                <Puff
                    color="#4583A1"
                    height="135"
                    width="135"
                    ariaLabel="puff-loading"
                    visible={visible}
                />
            </div>


            <h3 id="thankYouContent">
                {thankYou1}
                <br />
                {thankYou2}
            </h3>
        </div>
    );
}

